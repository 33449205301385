import React from 'react';
import NavBar, {
  NavItem,
  NavLink,
  Submenu,
  ExpandButton,
} from '../../shared/navbar';
import { MobileMenuWrap } from './mobilemenu.style';
import { getClosest, getSiblings } from '../../../utils/utilFunctions';

const MobileMenu = ({ menuData }) => {
  const removeClassFromChildren = (parent) => {
    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].tagName === 'UL') {
        let child = parent.children[i].children;
        for (let j = 0; j < child.length; j++) {
          child[j].classList.remove('submenu-open');
        }
      }
    }
  };
  const onClickHandler = (e, selector) => {
    const target = e.target;
    const parentEl = target.parentElement;
    if (
      parentEl.classList.contains('menu-expand') ||
      target.classList.contains('menu-expand')
    ) {
      let element = target.classList.contains('icon') ? parentEl : target;
      const parent = getClosest(element, selector);
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        sibling.classList.remove('submenu-open');
        removeClassFromChildren(sibling);
      });
      removeClassFromChildren(parent);
      parent.classList.toggle('submenu-open');
    }
  };

  return (
    <MobileMenuWrap>
      <NavBar>
        {menuData.map((menu, i) => {
          return (
            <div key={i}>
              {menu._type === 'pageRef' && (
                <NavItem key={`mainmenu-${i}`} className="menu-item">
                  <NavLink path={
                    (menu?.page?.slug?.current === undefined)
                    ? `/`
                    : `/${menu?.page?.slug?.current}`}
                  >
                    {menu?.page.title}
                  </NavLink>
                </NavItem>
              )}

              {menu._type === 'submenu' && (
                <NavItem
                  key={`mainmenu-${i}`}
                  hasSubmenu="true"
                  className="menu-item"
                  id={`menu-item-${i}`}
                >
                  <ExpandButton
                    text={menu?.list[0].title}
                    onClick={(e) => onClickHandler(e, `#menu-item-${i}`)}
                  />

                  <Submenu>
                    {menu?.list.slice(1).map((subitem, i) => {
                      return (
                        <NavItem
                          key={`submenu-${i}`}
                          className="menu-item"
                        >
                          <NavLink path={`/${subitem.slug.current}`}>{subitem.title}</NavLink>
                        </NavItem>
                      );
                    })};
                  </Submenu>
                </NavItem>
              )}
            </div>
          );
        })}
      </NavBar>
    </MobileMenuWrap>
  );
};

export default MobileMenu;
