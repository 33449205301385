import React from 'react';
import { HorizontalNav } from './horizontal-menu.stc';
import NavBar, { NavItem, NavLink, Submenu } from '../../shared/navbar';
import { GoTriangleDown } from 'react-icons/go';

const HorizontalMenu = ({ menuData, ...restProps }) => {
  return (
    <HorizontalNav {...restProps}>
      <NavBar>
        {menuData.map((menu, i) => {

          return (
            <React.Fragment key={i}>

              {menu._type === 'pageRef' && (
                <NavItem key={`mainmenu-${i}`}>
                  <NavLink path={
                    (menu?.page?.slug?.current === undefined)
                    ? `/`
                    : `/${menu?.page?.slug?.current}`}
                  >
                    {menu?.page.title}
                  </NavLink>
                </NavItem>
              )}

              {menu._type === 'submenu' && (
                <NavItem key={`mainmenu-${i}`} hasSubmenu="true">

                  <NavLink path={`/${menu?.list[0].slug.current}` || '/'}>
                    {menu?.list[0].title}
                  </NavLink>
                  
                  <GoTriangleDown className="icon"/>
                  {/* <i className="icon ti-angle-down"></i> */}

                  <Submenu>
                    {menu?.list.slice(1).map((subitem, i) => {
                      return (
                        <NavItem key={`submenu-${i}`}>
                          <NavLink path={`/${subitem.slug.current}`}>{subitem.title}</NavLink>
                        </NavItem>
                      );
                    })}
                  </Submenu>

                </NavItem>
              )}

            </React.Fragment>
          );
        })}

      </NavBar>
    </HorizontalNav>
  );
};

export default HorizontalMenu;