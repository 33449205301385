import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
const SEO = ({ title, ogtitle, description, ogdesc, image, pathname }) => (
  <StaticQuery
    query={query}
    render={({
      sanityGlobalSettings: {
        defaultTitle,
        defaultDescription,
        siteUrl,
        openGraph: {
          image: {
            asset: {
              defaultImage,
            },
          },
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        ogtitle: ogtitle || title,
        description: description || defaultDescription,
        ogdesc: ogdesc || description,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
      }
      return (
        <>
          <Helmet title={seo.title} titleTemplate={`%s | ${defaultTitle}`}>
            <html lang="en" />
            <meta name="title" content={seo.title} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta property="og:type" content="website" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(seo.title || seo.ogtitle) && <meta property="og:title" content={seo.ogtitle || seo.title} />}
            {(seo.description || seo.ogdesc) && (
              <meta property="og:description" content={seo.ogdesc || seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {(seo.title || seo.ogtitle) && <meta name="twitter:title" content={seo.ogtitle || seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  ogtitle: PropTypes.string,
  description: PropTypes.string,
  ogdesc: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  ogtitle: null,
  description: null,
  ogdesc: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    sanityGlobalSettings {
      defaultTitle: title
      defaultDescription: description
      siteUrl: siteUrl
      openGraph {
        image {
          asset {
            defaultImage: url
          }
        }
      }
    }
  }
`