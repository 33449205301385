import React from 'react';
import { Link } from 'gatsby';
import { FooterMenuWrap, FooterMenuList } from './footer-menu.stc';

const FooterMenu = (navItems) => {
  return (
    <FooterMenuWrap>
      {Object.values(navItems)[0].map((item, i) => {
        let path = `/${item.page.slug.current}`
        return (
          <FooterMenuList key={i}>
            <Link to={path} className="no-cursor">
              {item.page.title}
            </Link>
          </FooterMenuList>
        );
      })}
    </FooterMenuWrap>
  );
};

export default FooterMenu;
